<template>
  <v-container class="mt-12 pt-8" style="background:#fafafa;">
    <v-row style="background:#fafafa;">
      <v-col cols="12" class="pa-0">
        
        <v-row class="py-1 px-5">
          <v-col cols="12" md="12">
            <v-row>
              <v-col cols="12" class="px-5" PANNELS>
                <v-card NotificationPanel :loading="loading">
                  <v-card-text class="py-0">
                    <v-row>
                      <v-col cols="12">
                        <v-row class="align-center">
                          <v-col cols="2" class="text-center" TITLE>
                            <v-icon size="40" color="primary">notifications</v-icon>
                          </v-col>
                          <v-col cols="10" TITLE>
                            <div :class="`${arabic?'arFont':'enFont'} black--text`">الاشعارات الخاصة بك</div>
                            <div :class="`${arabic?'arFont':'enFont'} grey--text`">الاشعارات الخاصة بطلباتك وحسابك</div>
                          </v-col>
                        </v-row>
                      </v-col>
                      <v-col cols="12">
                        <v-row class="align-center">
                          <v-col cols="12" md="6" :class="`${arabic?'arFont':'enFont'}`">
                            <v-btn color="success" :class="`${arabic?'arFont':'enFont'}`" @click="markAllAsRead()">
                              <v-icon left>done_all</v-icon>
                              قراءة كل الاشعارات
                            </v-btn>
                          </v-col>
                          <v-col cols="12" md="6" :class="`${arabic?'arFont':'enFont'}`">
                            <Paging 
                              :resourceSettings="resourceSettings" 
                              :resource="resource"
                              @loadResource="loadResource"
                              @pageByNumber="pageByNumber"
                            />
                          </v-col>
                          <v-col cols="12" :class="`${arabic?'arFont':'enFont'}`">
                            <Notifications :notifications="resource.data"/>
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-row>
                  </v-card-text>
                  
                </v-card>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Search from '@/components/Search'
import Paging from '@/components/Paging'
import Notifications from '@/components/Notifications'
export default {
  components: { Paging, Notifications },
  data() {
    return {
      loading:false,
      dialog:false,
      vresource:[],
      resource:[],
      search:null,
      rules: {
        required: value => !!value || this.i18n['required'],
        email: value => /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(value) || this.i18n['valid_email']
      },
      resourceKeys:[
        
      ],
      resourceSettings:{
        resourceName:null,
        search:false,
        create:false,
        pagination:true,
      },
      newResource:{},
      editing:false,
      deleting:false,
      firstPanel:true,
      secondPanel:false,
      selectedPanel:'firstPanel',
      subitems:[
        {
          text:'الدعم',
          icon:'support_agent',
          value:'firstPanel'
        },
        {
          text:'المقترحات',
          icon:'add_box',
          value:'secondPanel'
        },
      ],
      text:null,
    }
  },
  methods: {
    async markAllAsRead() {
      this.loading = true
      let reads = this.notifications.map(x=>x.id)
      await this.$store.dispatch('postResource', {resource:`/notifications-read`, value:reads})
      await this.loadResource()
      this.loading = false
    },
    filterObject(item, queryText, itemText) {
      let v = Object.values(item).filter(x=>typeof x == 'string')
      let a = false
      v.forEach(x=>{
        if(x.toLowerCase().includes(queryText.toLowerCase())) a = true
      })
      if(itemText && typeof itemText == 'string' && itemText.toLowerCase().includes(queryText.toLowerCase())) a = true
      return a
    },
    clipboardSuccessHandler (){
      this.$emit('snackbarNotification', `Copied`, 'success')
    },
    clipboardErrorHandler () {
      this.$emit('snackbarNotification', `Failed`, 'error')
    },
    async pageByNumber(n){
      return new Promise(async (res, rej)=>{
        this.loading = true
        if(!this.search){
          this.resource = await this.$store.dispatch('getResource', {resource:this.$route.path, page:`?page=${n}` + this.buildFilter()})
        } else {
          this.resource = await this.$store.dispatch('searchResource', {resource:this.$route.path + `/search?page=${n}`, value:this.search})
        }
        this.loading = false
      })
    },
    goTo(path) {
      this.$router.push(path).catch(x=>{})
    },
    buildFilter(){
      let filter = ``
      return filter
    },
    async filterLeads(){
      await this.loadResource()
    },
    firstLoad(){
      if(this.user) {
      }
    },
    async loadResource() {
      return new Promise(async (res, rej)=>{
        this.loading = true
        if(!this.search){
          this.resource = await this.$store.dispatch('getResource', {resource:this.$route.path + '?' + this.buildFilter()})
        } else {
          this.resource = await this.$store.dispatch('searchResource', {resource:this.$route.path + '/search', value:this.search})
        }
        this.loading = false
        res()
      })
    },
    showPannel(v){
      this[v] = true
      let all = this.subitems.map(x=>x.value)
      all.filter(x=>x!=v).forEach(x=>this[x] = false)
    },
    setResourceName(){
      this.resourceSettings.resourceName = this.i18n[this.$route.path.replace('/', '')]
    }
  },
  computed:{
    api(){
      return this.$store.state.settings.apiBase
    },
    users(){
      let sa = this.$store.state.user && this.$store.state.user.role == 'superadmin'
      // console.log(sa)
      return this.$store.state.users.filter(x=>{
        if(!sa) {
          return x.superior == this.$store.state.user.level
        } else return x
      })
    },
    site(){
      return this.$store.state.site
    },
    items() {
      return this.$store.state.menu
    },
    superAdmin(){
      return this.$store.state.superAdmin
    },
    isLogin() {
      return this.$store.state.centralLogin
    },
    language(){
      return this.$store.state.language
    },
    globalLoading(){
      return this.$store.state.globalLoading
    },
    user(){
      return this.$store.state.user
    },
    i18n(){
      return this.$store.state.i18n[this.$store.state.language]
    },
    arabic(){
      if(this.$store.state.language == 'ar') return true
    },
    routeTitle(){
      let main = this.$store.state.menu.filter(x=>x.path == this.$route.path)
      let sub = [].concat(...this.$store.state.menu.map(x=>x.subs)).filter(x=>x).filter(x=>x.path == this.$route.path)
      if(main.length) {
        return this.$store.state.i18n[this.$store.state.language][main[0].text]
      } else if(sub.length) {
        return this.$store.state.i18n[this.$store.state.language][sub[0].text]
      } else {
        return ''
      }
    },
    roles(){
      return this.$store.state.userRoles
    },
    notifications(){
      return this.$store.state.notifications
    },
  },
  async created(){
    this.setResourceName()
    await this.loadResource()
  },
}
</script>

<style>

</style>